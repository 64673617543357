import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { truncate } from 'lodash';
import moment from 'moment';

import {
  Container,
  PrevLink,
  NextLink,
  PrevTitle,
  NextTitle,
  Type,
  Date,
  Margin,
  StyledLink,
} from './journal-post-header.css';

import JournalLogoSmall from 'components/journal-logo-small';

const JournalPostHeader = ({ title, type, date, previous, next }) => (
  <Margin>
    <Container>
      <div className="max-w-4xl w-full">
        <span className="flex flex-wrap items-center pt-32 sm:pt-48 pb-2">
          {type &&
            type.length > 0 &&
            type.map((t, i) => (
              <span key={t}>
                <Type>
                  <StyledLink to="/news-and-stories">{t}</StyledLink>
                  {i < type.length - 1 && ','}
                </Type>
              </span>
            ))}
          {date && <Date>{moment(date).format('LL')}</Date>}
        </span>
        <h1 className="text-5p5xl text-brand-blue font-extralight mt-4 mb-10">
          {title}
        </h1>
      </div>
      <Portal>
        <JournalLogoSmall />
        {previous && (
          <PrevLink to={`/news-and-stories/${previous.slug}`}>
            <span className="border-solid border-black border-b-2 pb-1">
              Previous Journal Post
            </span>
            <PrevTitle>{truncate(previous.title)}</PrevTitle>
          </PrevLink>
        )}
        {next && (
          <NextLink to={`/news-and-stories/${next.slug}`}>
            <NextTitle>{truncate(next.title)}</NextTitle>
            <span className="border-solid border-black border-b-2 pb-1">
              Next Journal Post
            </span>
          </NextLink>
        )}
      </Portal>
    </Container>
  </Margin>
);

JournalPostHeader.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  previous: PropTypes.object,
  next: PropTypes.object,
};

export default JournalPostHeader;
