import React from 'react';
// import PropTypes from 'prop-types';
import { Container, StyledSvg } from './journal-logo-small.css';

import newsStoryLogo from 'images/news-story.png';
import journalHeader2 from 'images/journal/journal-2.png';

const JournalLogoSmall = () => (
  <Container to="/news-and-stories">
    <div  style={{width:"100%",display:"flex",justifyContent:"center"}}>
      <img src={newsStoryLogo} alt='contentfull' style={{width:"100%"}}/>
    </div>
    {/* <StyledSvg viewBox="0 0 484.98 188.99" className="w-full">
      <defs>
        <pattern id="journal-header-1" patternUnits="objectBoundingBox" width="1" height="1" viewBox="0 0 1307 363">
          <image preserveAspectRatio="none" xlinkHref={journalHeader2} width="1307" height="363"></image>
        </pattern>
      </defs>
      <title>News + Stories</title>
      <rect fill="url(#journal-header-1)" class="bg-brand-blue" x="28.79" width="456.19" height="102.81"></rect>
        <g transform=" translate(0 110) scale(1.8)">
          <path d="M0,30.77V.49H4.27L13.97,15.81c2.25,3.55,4,6.74,5.44,9.84l.09-.04c-.36-4.04-.45-7.73-.45-12.44V.49h3.68V30.77h-3.95L9.16,15.41c-2.11-3.37-4.13-6.83-5.66-10.11l-.13,.04c.22,3.82,.31,7.46,.31,12.49v12.94H0Z" />
          <path d="M30.73,20.62c.09,5.35,3.5,7.55,7.46,7.55,2.83,0,4.54-.49,6.02-1.12l.67,2.83c-1.39,.63-3.77,1.35-7.23,1.35-6.69,0-10.69-4.4-10.69-10.96s3.86-11.72,10.2-11.72c7.1,0,8.98,6.24,8.98,10.24,0,.81-.09,1.44-.13,1.84h-15.27Zm11.59-2.83c.04-2.52-1.03-6.42-5.48-6.42-4,0-5.75,3.68-6.06,6.42h11.54Z" />
          <path d="M51.7,9.03l2.88,11.05c.63,2.43,1.21,4.67,1.62,6.92h.13c.49-2.2,1.21-4.54,1.93-6.87l3.55-11.1h3.32l3.37,10.87c.81,2.61,1.44,4.9,1.93,7.1h.13c.36-2.2,.94-4.49,1.66-7.05l3.1-10.92h3.91l-7.01,21.74h-3.59l-3.32-10.38c-.76-2.43-1.39-4.58-1.93-7.14h-.09c-.54,2.61-1.21,4.85-1.98,7.19l-3.5,10.33h-3.59l-6.56-21.74h4.04Z" />
          <path d="M81.49,26.73c1.17,.76,3.23,1.57,5.21,1.57,2.88,0,4.22-1.44,4.22-3.23,0-1.89-1.12-2.92-4.04-4-3.91-1.39-5.75-3.55-5.75-6.15,0-3.5,2.83-6.38,7.5-6.38,2.2,0,4.13,.63,5.35,1.35l-.99,2.88c-.85-.54-2.43-1.26-4.45-1.26-2.34,0-3.64,1.35-3.64,2.96,0,1.8,1.3,2.61,4.13,3.68,3.77,1.44,5.71,3.32,5.71,6.56,0,3.82-2.96,6.51-8.13,6.51-2.38,0-4.58-.58-6.11-1.48l.99-3.01Z" />
          <path d="M119.08,6.87v10.56h10.15v2.7h-10.15v10.65h-2.88v-10.65h-10.15v-2.7h10.15V6.87h2.88Z" />
          <path d="M141.63,26.01c1.75,1.08,4.31,1.98,7.01,1.98,4,0,6.33-2.11,6.33-5.17,0-2.83-1.62-4.45-5.71-6.02-4.94-1.75-8-4.31-8-8.58,0-4.72,3.91-8.22,9.79-8.22,3.1,0,5.35,.72,6.69,1.48l-1.08,3.19c-.99-.54-3.01-1.44-5.75-1.44-4.13,0-5.71,2.47-5.71,4.54,0,2.83,1.84,4.22,6.02,5.84,5.12,1.98,7.73,4.45,7.73,8.89,0,4.67-3.46,8.71-10.6,8.71-2.92,0-6.11-.85-7.73-1.93l.99-3.28Z" />
          <path d="M167.91,2.79v6.24h5.66v3.01h-5.66v11.72c0,2.7,.76,4.22,2.96,4.22,1.03,0,1.8-.13,2.29-.27l.18,2.96c-.76,.31-1.98,.54-3.5,.54-1.84,0-3.32-.58-4.27-1.66-1.12-1.17-1.53-3.1-1.53-5.66V12.04h-3.37v-3.01h3.37V3.82l3.86-1.03Z" />
          <path d="M196.53,19.72c0,8.04-5.57,11.54-10.83,11.54-5.88,0-10.42-4.31-10.42-11.19,0-7.28,4.76-11.54,10.78-11.54s10.47,4.54,10.47,11.19Zm-17.25,.22c0,4.76,2.74,8.36,6.6,8.36s6.6-3.55,6.6-8.45c0-3.68-1.84-8.36-6.51-8.36s-6.69,4.31-6.69,8.45Z" />
          <path d="M200.61,15.81c0-2.56-.04-4.76-.18-6.78h3.46l.13,4.27h.18c.99-2.92,3.37-4.76,6.02-4.76,.45,0,.76,.04,1.12,.13v3.73c-.4-.09-.81-.13-1.35-.13-2.79,0-4.76,2.11-5.3,5.08-.09,.54-.18,1.17-.18,1.84v11.59h-3.91V15.81Z" />
          <path d="M218.99,2.92c.04,1.35-.94,2.43-2.52,2.43-1.39,0-2.38-1.08-2.38-2.43s1.03-2.47,2.47-2.47,2.43,1.08,2.43,2.47Zm-4.4,27.85V9.03h3.95V30.77h-3.95Z" />
          <path d="M226.4,20.62c.09,5.35,3.5,7.55,7.46,7.55,2.83,0,4.54-.49,6.02-1.12l.67,2.83c-1.39,.63-3.77,1.35-7.23,1.35-6.69,0-10.69-4.4-10.69-10.96s3.86-11.72,10.2-11.72c7.1,0,8.98,6.24,8.98,10.24,0,.81-.09,1.44-.13,1.84h-15.27Zm11.59-2.83c.04-2.52-1.03-6.42-5.48-6.42-4,0-5.75,3.68-6.06,6.42h11.54Z" />
          <path d="M245.26,26.73c1.17,.76,3.23,1.57,5.21,1.57,2.88,0,4.22-1.44,4.22-3.23,0-1.89-1.12-2.92-4.04-4-3.91-1.39-5.75-3.55-5.75-6.15,0-3.5,2.83-6.38,7.5-6.38,2.2,0,4.13,.63,5.35,1.35l-.99,2.88c-.85-.54-2.43-1.26-4.45-1.26-2.34,0-3.64,1.35-3.64,2.96,0,1.8,1.3,2.61,4.13,3.68,3.77,1.44,5.71,3.32,5.71,6.56,0,3.82-2.96,6.51-8.13,6.51-2.38,0-4.58-.58-6.11-1.48l.99-3.01Z" />
        </g>
        <text 
        class="!font-micro" 
        fill="rgba(0,0,0,0.01)" 
        transform="translate(0 160.33)" 
        style={{ fontSize: '500px', letterSpacing: '0em', fontWeight: "100" }}
        >
          News + Stories
        </text>
    </StyledSvg> */}
    {/* <p className='font-display font-extralight text-4xl w-full' >News + Stories</p> */}
  </Container>
);

// JournalLogoSmall.propTypes = {
// };

export default JournalLogoSmall;
